import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import subheadPreviewData from '../../../../data/previews/subhead.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Subhead = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Subhead;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Subhead"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Subhead" />
      <V5Notice
        componentName="Subhead"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/typography-subhead--docs"
      />

      <PageNavigation links={['Size', 'Usage', 'Microcopy', 'Platform']} />

      <Section title="Size">
        <Paragraph>
          Consider the piece of interface in question as you choose between
          these three.
        </Paragraph>
        <ComponentPreview
          name="SubheadSize"
          layout="default"
          previewData={subheadPreviewData.levels}>
          <Subhead>We help you win.</Subhead>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <Paragraph>
          The subhead should be the first choice to heirarchally label different
          areas of content. Use it to separate long runs of copy into mangeable
          pieces. For example, the subhead should be the first choice when
          breaking a long form into many sections.
        </Paragraph>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          Use the{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product">
            UI label guidelines
          </Link>{' '}
          when typesetting subheads.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
